body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.popup-content {
  max-width: 250px;
  border-radius: 8px;
}

.popup-overlay {
  top: -40% !important;
}

.vertical-spacing{
  height: 10px;
}

.loading-logo {
  animation: scaling 0.8s infinite alternate;
}

@keyframes scaling {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@-webkit-keyframes scaling {
  from {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.5);
  }
}

.card_number_input{
  width: 280px;
  background: #F8F8FC;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
._loading_overlay_spinner{
  margin-left: 10rem !important;
}

.sc-eeMvmM{
background: #f8f8fc !important;
}


@media (min-width: 600px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }
}

@media (max-width: 600px) {

  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }

  .popup-content {
    max-width: 100%;
    width: 80% !important;
    border-radius: 8px;
  }

  .popup-overlay {
    top: 0 !important;
  }

  .vertical-spacing{
    height: 0px !important;
  }

  .card_number_input{
    width: 350px !important;
  }

  ._loading_overlay_spinner{
    margin-left: 0rem !important;
  }

}

@media (max-width: 370px) {
  .card_number_input{
    width: 280px !important;
  }
 
}

@media (max-width: 311px) {
  .card_number_input{
    width: 248px !important;
  }
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: green !important;
  }




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

